<template>
    <tr class="text-[#525A60] w-full h-[50px]">
        <td class="w-[11%] uppercase font-bold px-[2px] text-start py-1 text-[0.9rem]">
            <input :value="groupName" @change="e => $emit('update:groupName', e.target.value)" class="w-full uppercase text-start text-[0.9rem] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
        </td>
        <td class="border-b-[1px] border-[#E1DAD8] text-[#C9ADA7] text-start py-1 text-[0.9rem] flex items-center" colspan="7">
            <div class="w-max truncate mr-[10px]">
                вкажіть максимальну к-сть клієнтів
            </div>
            <input :value="maxClients" @change="e => $emit('update:maxClients', e.target.value)" type="text" class="w-[31px] text-center rounded-[6px] text-[0.9rem] bg-[#9A8C9833] border-[1px] border-[#9A8C98] text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
        </td>
    </tr>
    <SubscriptionItemRow 
        v-for="tariff in tariffs" 
        :subscriptionName="tariff.tariff_name"
        :subscriptionTrainingCount="tariff.tariff_training_count"
        :subscriptionDays="tariff.tariff_days"
        :subscriptionPrice="tariff.tariff_price"
        :subscriptionDescription="tariff.tariff_description"
        :subscriptionColor="tariff.tariff_color"
        :subscriptionMonth="tariff.tariff_months"

        @changeSubscriptionName="e => $emit('changeTariffName', {tariff_id: tariff.tariff_id, value: e})"
        @changeSubscriptionTrainingCount="e => $emit('changeTariffTrainingCount', {tariff_id: tariff.tariff_id, value: e})"
        @changeSubscriptionDays="e => $emit('changeTariffDays', {tariff_id: tariff.tariff_id, value: e})"
        @changeTariffMonths="e => $emit('changeTariffMonths', {tariff_id: tariff.tariff_id, value: e})"
        @changeSubscriptionPrice="e => $emit('changeTariffPrice', {tariff_id: tariff.tariff_id, value: e})"
        @changeSubscriptionDescription="e => $emit('changeTariffDescription', {tariff_id: tariff.tariff_id, value: e})"
        @changeColor="e => $emit('changeColor', {tariff_id: tariff.tariff_id, value: e})"

        @selectTariffForView="$emit('selectTariffForView', tariff.tariff_id)" 
        @deleteTariff="$emit('deleteTariff', tariff.tariff_id)"
    />
    <tr class="text-[#525A60] w-full h-[50px]">
        <td class="w-[11%] px-[2px] text-start py-1 text-[0.9rem]">
        </td>
        <td colspan="8" class="border-b-[1px] border-[#E1DAD8] w-[16%] text-start py-1 text-[0.9rem]">
            <input :value="newTariffName" @change="e => newTariffName = e.target.value" class="w-full text-start text-[0.9rem] bg-transparent text-[#525A60] placeholder:text-[#525A60] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="назва абонементу">
        </td>
    </tr>
</template>

<script>
    import { uuid } from 'vue3-uuid'
    import { ref, onMounted, watch } from 'vue'
    import SubscriptionItemRow from './SubscriptionItemRow.vue'

    export default {
        name: 'SubscriptionGroupRow',

        props: {
            groupName: {
                type: String,
                default: ''
            },
            maxClients: {
                type: Number,
                default: null
            },
            tariffs: {
                type: Array,
                default: []
            },
            groupId: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const newTariffName = ref('')

            watch(newTariffName, (val) => {
                if (!val) return
                emit('addNewTariff', {
                    tariff_id: uuid.v4(),
                    tariff_name: val,
                    tariff_training_count: null,
                    tariff_days: null,
                    tariff_price: null,
                    tariff_description: '',
                    tariff_color: '',
                    tariff_months: null,
                    tarrif_group_name: props.groupName,
                    embed_group_id: props.groupId
                })
                newTariffName.value = ''
            })


            return {
                newTariffName
            }
        },

        components: {
            SubscriptionItemRow
        }
    }
</script>

<style lang="scss" scoped>

</style>