<template>
  <div class="flex justify-start">
    <svg :height="height" :width="width" ref="svg">
      <!-- Create your SVG elements here -->
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BarChart',

  props: {
    data: {
      type: Array,
      required: true
    },
    bottomBarColor: {
      type: String,
      default: '#00bfff'
    },
    topBarColor: {
      type: String,
      default: '#00bfff'
    },
    lineColor: {
      type: String,
      default: '#d3d3d3'
    },
    labels: {
      type: Array,
      required: true
    },
    height: {
      type: Number,
      default: 230
    },
    width: {
      type: Number,
      default: 864
    }
  },

  mounted() {
    this.drawChart();
  },

  methods: {
    drawChart() {
      const svg = this.$refs.svg;
      const max = Math.max(...this.data.map(bar => bar[1] + bar[0]));
      const barWidth = this.width / (this.data.length * 2) > 36 ? 36 : this.width / (this.data.length * 2);
      const spacing = 1;
      const marginBottom = 25;
      const marginTop = 45;
      const barMaxHeight = this.height - (marginBottom + marginTop);

      this.data.forEach((bar, i) => {
        const fullBarValue = bar[0] + bar[1];
        const currentBarPercentFromFull = (100 * fullBarValue) / max;
        const currentBlockFullHeight = (barMaxHeight * currentBarPercentFromFull) / 100;

        const topBlockHeight = (barMaxHeight * (bar[0] * 100) / max) / 100;
        const bottomBlockHeight = (barMaxHeight * (bar[1] * 100) / max) / 100;

        // Create top bar
        const topBar = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        topBar.setAttribute('x', (i * barWidth * 2) + spacing);
        topBar.setAttribute('y', this.height - currentBlockFullHeight - marginBottom);
        topBar.setAttribute('width', barWidth);
        topBar.setAttribute('height', bottomBlockHeight);
        topBar.setAttribute('fill', this.topBarColor);
        svg.appendChild(topBar);

        // Create bottom bar
        const bottomBar = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        bottomBar.setAttribute('x', (i * barWidth * 2) + spacing);
        bottomBar.setAttribute('y', this.height - currentBlockFullHeight + bottomBlockHeight - marginBottom);
        bottomBar.setAttribute('width', barWidth);
        bottomBar.setAttribute('height', topBlockHeight);
        bottomBar.setAttribute('fill', this.bottomBarColor);
        svg.appendChild(bottomBar);

        // Create label
        const label = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        label.setAttribute('x', (i * barWidth * 2) + spacing + (barWidth / 2));
        label.setAttribute('y', this.height + 10 - marginBottom / 2);
        label.setAttribute('fill', '#fff');
        label.setAttribute('font-size', '12px');
        label.setAttribute('text-anchor', 'middle');
        label.textContent = this.labels[i];
        svg.appendChild(label);

      });

      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      let d = `M`;
      for (let j = 0; j < this.data.length; j++) {
        const dataPoint = this.data[j];
        const fullBarValue = dataPoint[0] + dataPoint[1];
        const currentBarPercentFromFull = (100 * fullBarValue) / max;
        const currentBlockFullHeight = (barMaxHeight * currentBarPercentFromFull) / 100;
        const topBlockHeight = (barMaxHeight * (dataPoint[0] * 100) / max) / 100;
        const bottomBlockHeight = (barMaxHeight * (dataPoint[1] * 100) / max) / 100;
        
        const x = (j * barWidth * 2) + spacing + (barWidth / 2);
        const y = this.height - currentBlockFullHeight - 24;
        if (j === 0) {
          d += `${x},${y}`;
        } else {
          d += ` L ${x},${y}`;
        }
      
      }
      path.setAttribute('d', d);
      path.setAttribute('stroke', this.lineColor);
      path.setAttribute('stroke-width', '6');
      path.setAttribute('fill', 'none');
      svg.appendChild(path);

      // Create data points
      this.data.forEach((bar, i) => {
        const fullBarValue = bar[0] + bar[1];
        const currentBarPercentFromFull = (100 * fullBarValue) / max;
        const currentBlockFullHeight = (barMaxHeight * currentBarPercentFromFull) / 100;

        const x = (i * barWidth * 2) + spacing + (barWidth / 2);
        const y = this.height - currentBlockFullHeight - marginBottom;
        const dataPoint = bar[0] + bar[1];

        // Create data point circle
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circle.setAttribute('cx', x);
        circle.setAttribute('cy', y);
        circle.setAttribute('r', '4');
        circle.setAttribute('stroke', this.lineColor);
        circle.setAttribute('stroke-width', '2');
        circle.setAttribute('fill', '#11204D');
        svg.appendChild(circle);

        // Create data point label
        const labelText = dataPoint.toLocaleString();
        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', x);
        text.setAttribute('y', y - 10);
        text.setAttribute('fill', this.lineColor);
        text.setAttribute('font-size', '12px');
        text.setAttribute('font-weight', 'bold');
        text.setAttribute('text-anchor', 'middle');
        text.textContent = labelText;
        svg.appendChild(text);
      });
    }
  }
};
</script>

<style scoped>
/* .chart-container {
  width: 100%;
  height: 100%;
} */
</style>
