<template>
    <div>
        <Calendardata />
    </div>
</template>
<script>
import Calendardata from "./schedulerComponents/datacalendar.vue";
export default {
    name: 'Scheduler',

    components: {
        Calendardata
    },
}
</script>
<style lang="scss">
    
</style>