
<template>
  <div class="row row-sm px-[20px]">
    <div class="w-[90%] mx-auto mb-[30px]">
      <div class="card custom-card">
        <div class="card-header">
          <div class="text-center font-bold text-[21px] mb-[20px]">Розклад роботи адміністраторів</div>
        </div>
        <div v-if="loaded" class="card-body">
          <FullCalendar ref="fullCalendarRef" id="calendar2" :editable="true" :droppable="true" :options="calendarOptions" @eventReceive="handleEventReceive" />
        </div>
      </div>

      <div class="flex justify-center mt-[16px]">
        <button @click="updateAdminScheduleMethod" class="bg-green-emb hover:bg-[#6BA292d6] w-[228px] text-[#525A60] px-4 py-[6px] text-[14px] rounded-md">Додати</button>
      </div>
    </div>
  </div>
</template>



<script>
import { ref, reactive, onMounted } from 'vue';
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import { INITIAL_EVENTS } from "./calendarData.js";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import esLocale from "@fullcalendar/core/locales/uk";

import { uuid } from 'vue3-uuid';

import { updateAdminSchedule, getAdminSchedule } from "@/services/apiRequests";

export default {
  setup() {
    const allEvents = ref([
    ])

    const loaded = ref(false);

    const fullCalendarRef = ref(null);
    const calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
      locale: esLocale,
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      initialView: "dayGridMonth",
      initialEvents: allEvents.value,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      weekends: true,
      droppable: true,
      eventReceive: handleEventReceive,
      select: function (arg) {
        var title = prompt('Ім\'я адміністратора:');
        if (fullCalendarRef) {
          if (title) {
            fullCalendarRef.value.getApi().addEvent({
              title: title,
              start: arg.start,
              end: arg.end,
              allDay: arg.allDay
            })
          }
          fullCalendarRef.value.getApi().unselect();
        }
      },
      eventClick: function (arg) {
        if (confirm('Дійсно бажаєте видалити цю подію?')) {
          arg.event.remove()
        }
      },
    };
    function handleEventReceive(info) {
      // Check if the dragged event comes from external-events
      if (info.draggedEl.classList.contains('fc-event')) {
        const title = info.draggedEl.querySelector('.fc-event-main').innerText;
        const className = info.draggedEl.className;
        // Add the event to fullCalendarRef
        if (fullCalendarRef) {
          fullCalendarRef.value?.getApi().addEvent({
            title,
            start: info.event.start,
            allDay: info.event.allDay,
            className,
          });
        }

        // Remove the dragged element from the external-events
        info.draggedEl.remove();
      }
    }
    const calendarNewEvents = reactive([
      { color: 'primary', title: "Calendar Events", id: "1" },
      { color: 'secondary', title: "Birthday EVents", id: "2" },
      { color: 'success', title: "Holiday Calendar", id: "3" },
      { color: 'info', title: "Office Events", id: "4" },
      { color: 'warning', title: "Other Events", id: "5" },
      { color: 'danger', title: "Festival Events", id: "6" },
      { color: 'teal', title: "Timeline Events", id: "7" },
    ]);

    const updateAdminScheduleMethod = async () => {
      const data = fullCalendarRef.value.getApi().getEvents().map((event) => {
          return {
            id: uuid.v4(),
            title: event.title,
            start: event.start.toISOString(),
            end: event.end.toISOString(),
            allDay: event.allDay,
            className: event.classNames[0],
          };
        });

      console.log(data);

      await updateAdminSchedule({schedule: data});

      alert('Розклад адміністраторів оновлено');
    }

    onMounted(async () => {
      const data = await getAdminSchedule();
      allEvents.value = data.data.schedule;
      console.log(allEvents.value);
      calendarOptions.initialEvents = allEvents.value;

      loaded.value = true;
    });

    return {
      fullCalendarRef,
      calendarOptions,
      handleEventReceive,
      calendarNewEvents,
      loaded,
      updateAdminScheduleMethod
    };
  },
  components: {
    FullCalendar,
  },
  data() {
    return {
      dataToPass: {
        current: "Full Calendar",
        list: [
          'Apps', 'Full Calendar'
        ]
      },
    }
  },
  methods: {
    setupDraggable() {
      new Draggable(document.getElementById("external-events"), {
        itemSelector: ".fc-event",
        eventData: (eventEl) => {
          let event = {
            title: eventEl.innerText,
            duration: "01:00"
          };
          return event;
        }
      });
    }
  },
  mounted() {
    this.setupDraggable();
  },

};
</script>

<style lang="scss">

$primary: rgb(230, 204, 204);
$primary-01: rgb(230, 204, 204, 0.1);
$primary-03: rgb(230, 204, 204, 0.3);
$primary-09: rgb(230, 204, 204, 0.9);
$light: #f8f9fa;
$custom-white: #ffffff;
$default-border: #e5e9f2;


[dir="rtl"] {
  .fullcalendar-events-activity {
    li {
      padding-block-start: 0.25rem;
      // padding-inline-end: 2rem;
      padding-block-end: 0.25rem;
      padding-inline-start: 2rem;
    }
  }
}

.fullcalendar-events-activity {
  li {
    margin-block-end: 0.75rem;
    font-size: 0.8125rem;
    padding-block-start: 0.25rem;
    padding-inline-end: 1rem;
    padding-block-end: 0.25rem;
    padding-inline-start: 2rem;
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 0.75rem;
      height: 0.75rem;
      border: 0.125rem solid $primary-03;
      color: black;
      border-radius: 3.125rem;
      background-color: $custom-white;
      inset-inline-start: 0.25rem;
      inset-block-start: 0.563rem;
    }

    &::after {
      position: absolute;
      content: "";
      height: 100%;
      background-color: transparent;
      border-inline-end: 2px dashed $primary-01;
      color: black;
      inset-inline-start: 0.563rem;
      inset-block-start: 1.25rem;
    }

    &:last-child {
      margin-block-end: 0;

      &::after {
        border-inline-end: 0px dashed $default-border;
        color: black;
      }
    }
  }
}

#full-calendar-activity {
  max-height: 30rem;
  overflow-y: auto;
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid {
  border: 0px !important;
  border-top: 1px solid $default-border !important;
  color: black;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
  padding: 0 0.25rem !important;
}

.fc .fc-button-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: black !important;
  &.fc-button-active {
    background-color: $primary-09 !important;
  }
}

.fc .fc-non-business {
  background: $custom-white !important;
}

.fc .fc-button-primary:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none !important;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid $default-border !important;
  border-top: 0px !important;
}

.fc-list-table { 
  td,
  th {
    border-left: 0 !important;
    border-right: 0 !important;
  }
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: $primary-01 !important;
}

.fc-theme-standard .fc-list {
  border: 1px solid $default-border !important;
}

.fc .fc-list-event:hover td {
  background-color: $light !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

.fc-theme-standard .fc-list-day-cushion {
  background-color: $light !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid $default-border !important;
}

.fc-theme-bootstrap5 .fc-list,
.fc-theme-bootstrap5 .fc-scrollgrid,
.fc-theme-bootstrap5 td,
.fc-theme-bootstrap5 th {
  border: 1px solid $default-border !important;
}

@media (max-width: 420px) {
  .fc-scroller.fc-scroller-liquid {
    overflow: scroll !important;
  }
}
@media (max-width: 380px) {
  .fc .fc-daygrid-day-bottom {
    font-size: .75em !important;
    padding: 0px 3px 0 !important;
  }
  .fc .fc-daygrid-more-link {
    z-index: 99 !important;
  }
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar {
    display: block !important;
  }

  .fc-toolbar-chunk {
    margin-block-start: 0.5rem;
  }
}

</style>