import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: MainLayout,
    meta: { component: 'Dashboard' }
  },
  {
    path: '/trainers',
    name: 'trainers',
    component: MainLayout,
    meta: { component: 'Trainers' }
  },
  {
    path: '/groups',
    name: 'groups',
    component: MainLayout,
    meta: { component: 'Groups' }
  },
  {
    path: '/students',
    name: 'students',
    component: MainLayout,
    meta: { component: 'Students' }
  },
  { 
    path: '/students/:id', 
    name: 'current-student',
    component: MainLayout,
    meta: { component: 'CurrentStudent' }
  },
  {
    path: '/transaction-log',
    name: 'transaction-log',
    component: MainLayout,
    meta: { component: 'TransactionLog' }
  },
  {
    path: '/locations',
    name: 'locations',
    component: MainLayout,
    meta: { component: 'Locations' }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: MainLayout,
    meta: { component: 'Calendar' }
  },
  {
    path: '/calendar/training/:training_id',
    name: 'calendarForTraining',
    component: MainLayout,
    meta: { component: 'Calendar' }
  },
  {
    path: '/incomes',
    name: 'incomes',
    component: MainLayout,
    meta: { component: 'Incomes' }
  },
  {
    path: '/incomes/tx_id/:tx_id',
    name: 'incomesWithCurrentTxId',
    component: MainLayout,
    meta: { 
      component: 'Incomes' 
    }
  },
  {
    path: '/incomes/balance_id/:balance_id',
    name: 'incomesWithCurrentBalanceId',
    component: MainLayout,
    meta: { 
      component: 'Incomes' 
    }
  },
  {
    path: '/outlays',
    name: 'outlays',
    component: MainLayout,
    meta: { component: 'Outlays' }
  },
  {
    path: '/outlays/tx_id/:tx_id',
    name: 'outlaysWithCurrentTxId',
    component: MainLayout,
    meta: { 
      component: 'Outlays' 
    }
  },
  {
    path: '/outlays/balance_id/:balance_id',
    name: 'outlaysWithCurrentBalanceId',
    component: MainLayout,
    meta: { 
      component: 'Outlays' 
    }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: MainLayout,
    meta: { component: 'Transactions' }
  },
  {
    path: '/transactions/tx_id/:tx_id',
    name: 'transactionsWithCurrentTxId',
    component: MainLayout,
    meta: { 
      component: 'Transactions' 
    }
  },
  {
    path: '/transactions/balance_id/:balance_id',
    name: 'transactionsWithCurrentBalanceId',
    component: MainLayout,
    meta: { 
      component: 'Transactions' 
    }
  },
  {
    path: '/admins',
    name: 'adminsView',
    component: MainLayout,
    meta: { 
      component: 'Admins' 
    }
  },
  {
    path: '/outlays-categories',
    name: 'OutlaysCategories',
    component: MainLayout,
    meta: { 
      component: 'OutlaysCategories' 
    }
  },
  {
    path: '/club-outlays',
    name: 'ClubOutlays',
    component: MainLayout,
    meta: { 
      component: 'ClubOutlays' 
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: MainLayout,
    meta: { 
      component: 'Settings' 
    }
  },
  {
    path: '/trainer-statistics/:trainer_id',
    name: 'TrainerDashboard',
    component: MainLayout,
    meta: { 
      component: 'TrainerDashboard' 
    }
  },
  {
    path: '/admins/schedule',
    name: 'AdminsSchedule',
    component: MainLayout,
    meta: { 
      component: 'Scheduler' 
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
